<template>
  <div :class="bgColor" class="flex h-10 w-10 items-center justify-center flex-shrink-0 rounded-full">
    <h4 class="text-white">{{ initials }}</h4>
  </div>
</template>

<script>
export default {
  name: "StudentProfilePicture",
  props: ['name'],

  computed: {
    initials()
    {
      return this.name.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
    },

    bgColor()
    {
      let firstInitial = this.name[0];

      let color = 0;
      if(['A', 'B', 'C', 'D'].includes(firstInitial)) {
        color = 1;
      } else if (['E', 'F', 'G', 'H'].includes(firstInitial)) {
        color = 2;
      } else if (['I', 'J', 'K', 'L'].includes(firstInitial)) {
        color = 3;
      } else if (['M', 'N', 'O', 'P'].includes(firstInitial)) {
        color = 4
      } else if (['Q', 'R', 'S', 'T'].includes(firstInitial)) {
        color = 5
      } else if (['U', 'V', 'W', ].includes(firstInitial)) {
        color = 6
      }

      let possibleColors = ['bg-yellow-300', 'bg-blue-700', 'bg-green-700', 'bg-red-600', 'bg-purple-700', 'bg-indigo-600', 'bg-pink-600'];

      return possibleColors[color];
    }
  }
}
</script>

<style scoped>

</style>
